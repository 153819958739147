

.btn{
    display: flex;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: var(--hippie-blue);
    color:white;
    border-radius: 5px;
    border: 0;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 350% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
}

.lightBtn{
    display: flex;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: white;
    color:var(--hippie-blue);
    border-radius: 5px;
    border: 1px solid #DFE3EB;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 350% */
    letter-spacing: 0.32px;
    text-transform: uppercase;

}
