
.div{
    grid: none;
    display: flex;
    gap:30px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 70%;
    margin: 0 auto;
    padding-top: 85px;
    padding-bottom: 85px;
    align-items: center;
}


.content{
    display:grid;
    width: 100%;
    gap: 20px;

}

.divider{
    height: 1px;
    align-self: stretch;

    stroke-width: 1px;
    stroke: var(--light-theme-system-border, #EEEEEF);
}

.adminTable {
    table-layout: fixed;
}



.navButtons{
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.displayCompanies{
    color: #737D8C;
}






@media only screen and (max-width: 1000px) {
    .content{
        grid: none;
        display: flex;
        gap:30px;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-top: 40px;
        align-items: center;
    }

    .div{
        width: 100%;
    }
    
    
  }