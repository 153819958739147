:root {
  --baltic-sea: #2F2D37;
  --pweter: #909E99;
  --sandstone: #746665;
  --hippie-blue: #60A1B9;
  --primary: #ECEFEF;
}


html,
body {
  margin: 0;
  font-family: Inter;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: var(--baltic-sea);
}

button{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 350% */
  letter-spacing: 0.32px;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1{
  font-family: Inter;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 98px;
}

h2{
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
}

h3{
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

h4{
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}

h5{
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

bodyXtraLarge{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}

.katex-display>.katex{
  white-space: pre-wrap;
}

/* ::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb{
  background-color: var(--sandstone);
  border: 3px solid var(--sandstone);
  border-radius: 10px;
}

::-webkit-scrollbar-track{
  background-color: white;
} */