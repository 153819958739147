.div{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;
    height: 100vh;
    width: 100vw;
    /* background-color: rgb(119, 94, 61); */
    background-color: white;

    overflow-y: auto;
    overflow-x: hidden;
}

