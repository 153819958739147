.div {
    min-height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 52px;
    padding-top: 120px;
    padding-bottom: 3cap;
    align-items: center;

}

.content {
    max-width: 750px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;

}

h3 {
    word-spacing: normal;
}



@media only screen and (max-width: 1425px) {
    .div {
        min-height: 1100px;
    }


}

@media only screen and (max-width: 600px) {
    .div {
        min-height: 1300px;
    }


}

@media only screen and (max-width: 450px) {
    .div {
        min-height: 1500px;
    }


}


@media only screen and (max-width: 350px) {
    .div {
        min-height: 1700px;
    }


}