

.infoContainer{
    display: block;
    box-sizing: inherit;
    text-align: left;
    margin: 30% 0;
}

.subheading{
    color: #737D8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    position: relative;
}

.h1{
    color: var(--baltic-sea);
    font-style: normal;
    position: relative;
}

.flexWrap{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px;
}

.keyStatistic{
    display: flex;
    align-items: center;
    gap: 16px;
}

.keyStatisticSubheading{
    color: var(--baltic-sea);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    position: relative;
}

.keyStatisticHeading{
    color: var(--hippie-blue);
    font-style: normal;
    position: relative;
}