.div{
    min-height: 800px;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 10fr;
    gap:52px;
    background-color: #ECEFEF;
    padding-top: 85px;
    padding-bottom:40px;
    
}

.content{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 24px;
    justify-content: center;
    align-content: flex-start;
}

.projectTitle{
    /* word-break: break-all;  */
}


.project{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    /* justify-content: none; */
}


.project:hover{
    /* Hover Shadow */
    box-shadow: 0px 20px 48px 0px rgba(10, 25, 49, 0.09);
    
}

.projectImageContainer{
    width: 400px;
    height: 313px;
    display: block;
    border-radius: 6px 6px 0px 0px;
    background-color: white;

}

.projectInfo{
    display: block;
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    background: var(--pewter, #909E99);
    text-align: left;
}

.projectInfoWhite{
    display: block;
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    background: white;
    text-align: left;
}

.projectInfoBlue{
    display: block;
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    background: var(--hippie-blue);
    text-align: left;
}

.projectLink{
    text-decoration: none;
    color: var(--baltic-sea);
}


@media only screen and (max-width: 800px) {
    .projectImageContainer{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
        width: 85vw;
        /* aspect-ratio: 400/313; */
        
        
        max-width: 400px;
        max-height: 313px;
        height: auto;


    }

    .projectImage{
        width: 100%;
        height: auto;
    }


/* 
    .projectInfo{
        text-align: left
    }
    .projectInfoWhite{
        text-align: left
    }
    .projectInfoBlue{
        text-align: left
    } */

    
    .project{
        text-align: left;
        word-spacing: normal;

    }

    
   .projectInfo{
    width: 85vw;
    max-width: 400px;
    
   }
    .projectInfoWhite{
        width: 85vw;
        max-width: 400px;
    }
    .projectInfoBlue{
        width: 85vw;
        max-width: 400px;
    }
    

    .div{
        grid-template-rows: auto;
    }

   
}