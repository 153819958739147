.div{
    min-height: 900px;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 10fr;
    gap:52px;
    background-color: #ECEFEF;
    padding-top: 85px;
    
}



.content{
    display:grid;
    width: 100%;
    grid-template-columns: 2fr 7fr;
    gap: 116px;

}

.navLinkContainer{
    display: block;
    width: 100%;
}

.experienceInfo{
    display: block;
    width: 100%;
    padding-top: 20px;
}

.navLinkDiv{
    display: flex;
    align-items: flex-start;
}

.navLinkLeftBorder{
    display: block;
    height: 471px;
    background-color: var(--baltic-sea);
    width: 5px;
    left:0;
}

.navLinkButtonContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
}

.navLinkButton{
    width: 100%;
    height: 50px;
    border-radius: 0px 10px 10px 0px;
    opacity: 0.5;
    flex-shrink: 0;
    border: none;
    padding-left: 30%;
    color: var(--baltic-sea);
    font-size: 14px;
    font-weight: 500;

    /* Inter Button */
    font-family: Inter;
    text-transform: uppercase;
}

.navLinkButton:hover{
    background: var(--hippie-blue);
}

.experienceCompany{
    color: var(--hippie-blue);
    text-align: left;
}

.experienceDate{
    text-align: left;
    word-spacing: normal;
    color: var(--baltic-sea);
}

.experienceInfo{
    text-align: left;
    color: var(--baltic-sea);
}

.navLinkButtonPerma{
    width: 100%;
    height: 50px;
    border-radius: 0px 10px 10px 0px;
    /* opacity: 0.5; */
    flex-shrink: 0;
    border: none;
    padding-left: 30%;
    color: var(--baltic-sea);
    background-color: var(--hippie-blue);

    color: var(--baltic-sea);
    font-size: 14px;
    font-weight: 500;

    /* Inter Button */
    font-family: Inter;
    text-transform: uppercase;
}

.experienceList{
    max-width: 550px;
    padding-left: 50px;
    list-style-type: circle;
}

.noBullet{
    list-style-type: none;


}


.experienceListItem{
    
}


@media only screen and (max-width: 800px) {
    .div{
        min-height: unset;
        justify-content: center   ;     
        grid-template-rows: unset;
    }


    .content{
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: inherit
    }
    
    .navLinkLeftBorder{
        display: block;
        height: 5px;
        background-color: var(--baltic-sea);
        top:0px;
        min-width: 100vw;
    }

    .navLinkContainer{
        width: 100vw;
        
    }

    .navLinkDiv{
        display: flex;
        flex-direction: column;
    }

    .navLinkButtonContainer{
        overflow: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        display: block;
        position: relative;
    }

    .navLinkButton{
        padding-left: 0;   
        width: auto; 
        padding-left: 15px;
        padding-right: 15px;    
    }

    .navLinkButton:hover{
        border-radius: 0px  0px 10px 10px;
    }

    
    .navLinkButtonPerma{
        width: auto;
        padding-left: 0;
        border-radius: 0px  0px 10px 10px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .experienceInfo{
        padding-left: 15px;
        padding-right: 15px
    }

    .experienceList{
        padding-left: inherit;
    }

    
}
