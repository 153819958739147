.div{
    display: block;
    width: 576px;
    height: 600px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;

    border-radius: 8px;
    background: white;
    box-shadow: 0px 5px 15px 0px rgba(29, 107, 125, 0.20);
}

@keyframes bake-pie {
  from {
    transform: rotate(0deg) translate3d(0,0,0);
  }
}

.header{
  text-align: left;
  padding: 15px;
}

.divider{
  /* This is a divider line across the whole div*/
  width: 100%;
  height: 1px;
  background-color: #E5E5E5;

}




.pie {
  display: inline-block;
  vertical-align: top;
  height: 200px;
  width: 200px;
  position: relative;
  margin: 60px 30px 80px 30px;
}
/* Creates doughnut circle*/
.pie::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 150px;
  height: 150px;
  background: white;
  border-radius: 50%;
  top: 25px;
  left: 25px;
}
/* Shadow */
.pie::after {
  content: "";
  display: block;
  width: 120px;
  height: 2px;
  background: rgba(0,0,0,0.1);
  border-radius: 50%;
  box-shadow: 0 0 3px 4px rgba(0,0,0,0.1);
  margin: 220px auto;
  
}
.slice {
  position: absolute;
  width: 200px;
  height: 200px;
  clip: rect(0px, 200px, 200px, 100px);
  animation: bake-pie 1s;
}
.slice span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  clip: rect(0px, 200px, 200px, 100px);
} 

.legend {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 15px;
}
.legend li {
  /* width: 100%;
  height: 1.25em;
  margin-bottom: 0.7em;
  padding-left: 0.5em;
  text-align: left;
  border-left: 1.25em solid black; */
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.circle{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: black;
}

.legendText{
  text-align: left;
  flex-grow: 1;
}

.legend h5 {
  margin-bottom: 0px;

}
.legend span {
  float: right;
}

@media only screen and (max-width: 800px) {
  .div{


    width: 450px;

  }
}

@media only screen and (max-width: 500px) {
  .div{


    width: 340px;

  }
}
