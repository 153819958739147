.div{
    min-height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:52px;
    padding-top: 120px;
    padding-bottom: 30px;
    align-items: center;

}

.content{
    max-width: 750px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    
}

h3{
    word-spacing: normal;
}
