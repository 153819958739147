
.button{
    border: 0;
    background: white;
    color: var(--baltic-sea);

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 285.714% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.divLarge{
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

@media only screen and (max-width: 700px) {
    .divLarge{
        display:none;
    }
  }