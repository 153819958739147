.div{

    flex-direction:  column;
    align-items: center;
    text-align:center;
    /* margin-left: 300px;
    margin-right: 300px; */
    /* height: 100px; */


    display: block;
    width: 100%;
    max-width: 1320px;
    padding-left: 20px;
    padding-right: 20px;
    
}