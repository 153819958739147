.greyBackground {
    display: flex;
    min-width: 100vw;
    background: linear-gradient(180deg, rgba(236, 239, 239, 0.50) 0%, #FFF 100%), #FFF;;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
.circle{
  width: 50vw;
  height: 50vw;
  max-width: 1000px;
  max-height: 1000px;
  border-radius: 50%;
  background-color: var(--baltic-sea);
  display: block;
  position: absolute;
  left: -400px;
  top: -400px;
}


@media only screen and (max-width: 800px) {
  .circle{
    height: 60vw;
    width: 60vw;
    left: -100px;
    top: -50px;
  }
}