/* .div{
    min-height: 742px;
    width: 100%;
    box-shadow: 0px 1px 0px 0px #DFE3EB;
    
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap:70px;


} */
.div{
    min-height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:52px;
    padding-top: 85px;
    
}




.content{
    display:grid;
    width: 100%;
    grid-template-columns: 2fr 7fr;
    gap: 116px;

}

.divider{
    height: 1px;
    align-self: stretch;

    stroke-width: 1px;
    stroke: var(--light-theme-system-border, #EEEEEF);
}






@media only screen and (max-width: 1300px) {
    .content{
        grid: none;
        display: flex;
        gap:30px;
        flex-direction: column;
        justify-content: center;
        position: relative;

        padding-top: 40px;
        align-items: center;
    }
  }