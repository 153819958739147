


.infoContainer{
    height: 100vh ;
    width: 100%;
    text-align:center;
    position: relative;
    /* display: flex;
    justify-content: center; */
    background-color: #60A1B9;
    display: block;
}

.div{
    display: flex;
    flex-direction: column;
    left: 0;
    text-align: left;
    max-width: 600px;
    gap: 20px;
    color: var(--baltic-sea);
    
}

.verticalGap{
    height: 20vh;
    display: block;
}







.highlight{
    color: var(--hippie-blue);
    position: relative;
}

.highlight::before{
    opacity: 0.15;
    background: linear-gradient(0deg, rgba(96, 161, 185, 0.20) 0%, rgba(96, 161, 185, 0.20) 100%), #60A1B9;
    position: absolute;
    display: inline-block;
    content: "";
    height: 36px;
    width: 105%;
    transform: translate(0px, 20px);

}

.buttonList{
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
}
.subheading{
    color: #737D8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    position: relative;
}

@media only screen and (max-width: 800px) {
    .div{
        padding-top: 20px;
        height: 100%;
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        left: 0;
        text-align: left;
        max-width: 600px;
        gap: 20px;
        color: var(--baltic-sea);
    }

    .verticalGap{
        height: 0;
        display: block;
        position:absolute;
    }
    
    .highlight::before{
        height: 20px;
    }
    
}
