

.particleContainer{
    height: calc(100vh - 100px);
    width: calc(50vw - 233px);
    
    left: calc(50% + 233px);
    min-width: 30vw;
    background-color: var(--baltic-sea);
    position: absolute;
    



    
}


@media only screen and (max-width: 800px) {
    .particleContainer{
        height: 25vh;
        width: 100vw;
        left:-20px;
        position: relative;
        display:block;

        
    }
  }