.dotsContainer{
    background-image: radial-gradient(var(--hippie-blue) 10%, transparent 0);
    background-size: 30px 30px;
    height: 100px;
    width: 150px;
    left:-50px;
    top: 25%;
    position: relative;
}

