.div{
    min-height: 800px;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 10fr;
    gap:52px;
    background-color: white;
    padding-top: 85px;
    padding-bottom:85px;
    
}

.content{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 74px;
    justify-content: center;
}

.service{
    width: 312px;
    height: 351px;
    background-color: var(--black-haze, #ECEFEF);
    border-radius: 10px;
    display: block;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    padding-top:33px;
    color: var(--baltic-sea);
    /* justify-content: none; */

}

.serviceMiddle{
    width: 312px;
    height: 351px;
    background-color: var(--white, #FFF);
    filter: drop-shadow(0px 20px 48px rgba(10, 25, 49, 0.09));
    border-radius: 10px;
    display: block;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    padding-top:33px;
    color: var(--baltic-sea);
    /* justify-content: none; */
}

.icon{
    height: 61px;
    width: 71px;
    position: absolute;

}

.serviceInfo{
    display: flex;
    width: 248px;
    height: 147px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    bottom:33px;
    position: absolute;
    text-align: left;

}

.line{
    width: 64px;
    height: 3px;
    border-radius: 50px;
    background: var(--hippie-blue);
}

a{
    text-decoration: none;
}

h3{
    word-spacing: 1000px;
    text-decoration: none
    
}

.customLink{
    text-decoration: none;
}



@media only screen and (max-width: 800px) {
    .service{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
        width: 80vw;
        
        
        max-width: 312px;
        max-height: 351px;
    }

    .serviceMiddle{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
        width: 80vw;
        
        
        max-width: 312px;
        max-height: 351px;
    }

   
    
    .serviceInfo{
        position: relative;
        bottom: 0px;
        width: 100%;
        height: unset;
   
    }

    .icon{
        position: relative;
    }

   
}
