/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.greyBackground {
  display: flex;
  min-width: 100vw;
  background-color: #ECEFEF;
  justify-content: center;
}

.whiteBackground{
  display: flex;
  min-width: 100vw;
  justify-content: center;
  position: relative;
}




@media only screen and (max-width: 800px) {

  .greyBackground {
    display: block;
    width: 100%;
  }
}