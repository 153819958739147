.circleContainer{
    width: 100vw;
    height: 100%;

    position: absolute;
    display: block;
    
    overflow: hidden;
    transition: font-size .25s linear; 
    -o-transition: font-size .25s linear; 
    -moz-transition: font-size .25s linear; 
    -webkit-transition: font-size .25s linear; 
    z-index: -1;
}



.circleLeft{
    width: 40vw;
    height: 40vw;
    max-width: 700px;
    max-height: 700px;


    background-color: var(--baltic-sea);
    border-radius: 50%;
    position: relative;
    top: 50px;
    left: 200px;
    transform: translate(-50%, -50%);
    display: block;
    overflow: hidden;
}

.circleRight{
    width: 190px;
    height: 190px;
    max-width: 190px;
    max-height: 190px;


    background-color: var(--baltic-sea);
    border-radius: 50%;
    position: absolute;
    right: 200px;
    bottom: 80px;
    display: block;
    overflow: visible;
}

@media all and (max-width: 1700px) {
    .circleLeft{
        left: 20px;
        
    }
    .circleRight{
        right: 100px;
    }
}

@media all and (max-width: 854px) {

    .circleRight{
        right: -40px;
        width: 160px;
        height: 160px;
    }


    
}