.div{
    display: flex;
    flex-direction:  row;
    align-items: center;
    text-align: center;
    /* margin-left: 300px;
    margin-right: 300px; */
    /* height: 100px; */
  
    background-color: #FFFFFF;
    height:100px;
    width: 100%;
    padding: 24px 5px;
    justify-content: space-between;
    align-items: center;
  }