#tsparticles{   
    width: calc(50vw - 233px);
    height: calc(100vh - 100px);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
    font-family:'Courier New', Courier, monospace;
}

@media only screen and (max-width: 800px) {
    #tsparticles{
        height: 25vh;
        width: 100vw;
        
    }
  }