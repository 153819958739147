

.button{
    display: flex;
    width: 72px;
    height: 48px;

    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 80px;
    border-width : 0;

    background: rgba(115, 125, 140, 0.10);
    color: #737D8C;

    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-transform: uppercase;
}
