
.div{

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  text-align: left;
  padding-bottom: 30px;

}


.heatmapContainer{
  display: block;
  width: 100%;
  height: 500px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;

  border-radius: 8px;
  background: white;
  box-shadow: 0px 5px 15px 0px rgba(29, 107, 125, 0.20);
}



.header{
  text-align: left;
  padding: 15px;
}

.divider{
  /* This is a divider line across the whole div*/
  width: 100%;
  height: 1px;
  background-color: #E5E5E5;

}



.parameterContainer{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    align-items: left;
    padding: 15px;
    
}


.parametersContainer{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    align-items: left;
}

.parameter{
    align-items: center;
}

.parameterInputContainer{
    display: flex;
    border-radius: 5px;
    padding: 5px;

}

.parameterInput{
    height: 30px;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-left: 10px;
    background-color: var(--baltic-sea);
    color: white;
    flex-grow: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.buttons{
    display: flex;
    flex-direction: row;
}

.buttonLeft{
    background-color: var(--baltic-sea);
    color: white;
    border: none;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
}

.buttonRight{
    background-color: var(--baltic-sea);
    color: white;
    border: none;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.callPrice{
  padding: 10px 10px;
  background-color: #6BE370;
  color: black;
  border-radius: 5px;
  border: 0;

  display: flex;
  flex-direction: column; /* Stack the h2 elements vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text inside the h2 tags */
  width: 240px; /* Adjust as needed */
  height: 120px; /* Adjust as needed */
}

.putPrice{
  padding: 10px 10px;
  background-color: #F94848;
  color: black;
  border-radius: 5px;
  border: 0;

  display: flex;
  flex-direction: column; /* Stack the h2 elements vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text inside the h2 tags */
  width: 240px; /* Adjust as needed */
  height: 120px; /* Adjust as needed */
}

.prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space out the buttons */
  align-items: center;
  width: 100%; /* Ensure the container takes up full width */
  padding: 0 32px; /* Optional: Adjust the padding to control spacing on the sides */
  gap: 16px; /* Keep a small gap between the buttons */
}


@media only screen and (max-width: 800px) {
  .div{


    width: 450px;

  }
}

@media only screen and (max-width: 500px) {
  .div{


    width: 340px;

  }
}
