.div{
    min-height: 742px;
    width: 100%;
    box-shadow: 0px 1px 0px 0px #DFE3EB;
    
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap:70px;

}




@media only screen and (max-width: 800px) {
    .div{
        grid: none;
        display: flex;
        gap:0;
        flex-direction: column;
        justify-content: center;
        position: relative;

        padding-top: 40px;
    }
  }