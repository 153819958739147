.div{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 130px;
    min-height: 744px;
    padding-top: 175px;
}

.text{
    display: flex;
    width: 426px;
    flex-direction: column;
    align-items: flex-start;
    gap: 97px;
    text-align: left;
}

.inTouch{
    width: 216px;
    height: 57px;

}

.followMeOn{
    display: flex;
    height: 104px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.socialMedia{
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.githubContainer{
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    border: 1px solid var(--gray-100, #DFE3EB);
    background: #231E1B;
}

.linkedinContainer{
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    border: 1px solid var(--gray-100, #DFE3EB);
    background: var(--hippie-blue, #60A1B9);
}




@media only screen and (max-width: 800px) {
    .div{
        min-height: unset;
        justify-content: center        
    }

    .inTouch{
        display: flex;
        flex-direction: column;
    }

    .text{
        width: unset;
        max-width: 426px;
    }

}
