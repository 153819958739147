.div{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    text-align: left;
    padding-bottom: 30px;

}

.question{
    padding: 15px;
}

.answers{
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.answerContainer{
    border-radius: 8px;
}

.answerContainer:hover {
    
    background: var(--hippie-blue);
    /* opacity: 60%; */
}

.answerContainer:hover h2{
    opacity: 100%;
}

.answerContainer{
    padding: 15px;
}

.selectedAnswer {
    background: var(--hippie-blue);
    color: #fff; /* Change the text color for selected answers */

}

.submitContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.questionNumber{
    margin-top: auto;
}


@media only screen and (max-width: 1000px) {
    .div{
        padding-left: 20px;
        padding-right: 20px;
    }
  }