.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.headerContainer h1{
    color: var(--baltic-sea);
}

.headerLine{
    width: 80px;
    height: 3px;

    border-radius: 3px;
    background: var(--hippie-blue);
}


