:root { 
    --size-of-photo: 30%;
  }

.photoContainer {
    position: relative;
    max-width: 540px;
    margin: auto 0;
    aspect-ratio: 1 / 1;
    /* left: 0px;
    top: 100px; */
    display: block;

}

.photo{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    display: block;
    position: absolute;
    z-index: 2;
    text-align: center;
    color: white;
}

.shadow{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: var(--hippie-blue);
    display: block;
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 1;
    opacity: 0.5;
}

.dotsOffset{
    display:flex;
    position: absolute;
    padding-top: 20%;

    left: -210px;
    z-index: 1;
}

.profilePicture{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}


@media only screen and (max-width: 800px) {
    .photoContainer{
        max-width: 400px;
        width: 80%;
        margin: auto;
        

    }    
    .shadow{

        left: 15px;
        top: 15px;

    }
}